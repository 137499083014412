import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
    ElementRef,
    AfterViewInit,
    AfterViewChecked
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { TranslateService } from "@ngx-translate/core";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";

import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { SHAREDCONSTServiceTsService } from "./services/sharedconstservice.ts.service";
import { RemoteConfigService } from "./services/remote-config.service";
import { TouchSequence } from "selenium-webdriver";
import { Directionality } from "@angular/cdk/bidi";
import { AngularFirestore } from "@angular/fire/firestore";
import { environment } from "environments/environment";

@Component({
    selector: "app",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent
    implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
    ngAfterViewChecked() { }

    ngAfterViewInit() {
        this._translateService.use("ar");
    }

    fuseConfig: any;
    direction = "rtl";
    private isRtl: boolean;

    /** Subscription to the Directionality change EventEmitter. */
    private _dirChangeSubscription = Subscription.EMPTY;
    navigation: any;
    Gotopage: String = "";
    // Private
    @ViewChild("main") mainView: ElementRef;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseSplashScreenService: FuseSplashScreenService,
        @Inject(DOCUMENT) private document: any,
        public afAuth: AngularFireAuth,
        public router: Router,
        dir: Directionality,
        private RemoteConfigServiceTmp: RemoteConfigService,
        private _fuseConfigService: FuseConfigService,public _AngularFirestore :AngularFirestore,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _remoteConfig: RemoteConfigService,
        private _platform: Platform
    ) {
        if (environment.production) {
            
            if(window){
              window.console.log=function(){};
            }
          }

        _remoteConfig.currentMessage.subscribe(data => {
            console.log(data);
            this.navigation = RemoteConfigService.navigation
            this._translateService.setDefaultLang("en");
            this._translateService.use("en");
            // Add languages
            this._translateService.addLangs(["en", "ar", "fr", "pt"]);

            // Set the default language
            this.isRtl = dir.value === "ltr";

            this._dirChangeSubscription = dir.change.subscribe(() => {
                // this.flipDirection();
            });
            console.log(RemoteConfigService.navigation);
           /* if (data == "OK_CONFIG") {
                this.navigation.forEach(element => {
                    if (
                        !(
                            RemoteConfigService.ACTIVE_SERVICE.indexOf(
                                element.id
                            ) > -1
                        )
                    ) {
                        const index: number = this.navigation.indexOf(element);
                        if (index !== -1) {
                            this.navigation.splice(index, 1);
                        }
                    }
                });
            }*/
            // Register the navigation to the service
            this._fuseNavigationService.register("main", this.navigation);

            // Set the main 'navigation as our current navigation
            if (data == "OK_CONFIG") {
                this._fuseNavigationService.setCurrentNavigation("main");
                this.afAuth.authState.subscribe(user => {                    
                    if (user) {
                        user.getIdToken().then(data => {
                            SHAREDCONSTServiceTsService.UserTocken = data;
                            this.goHOME(user);
                        });
                        this.afAuth.authState.subscribe(user=> {
                            if (user) 
                            {
                                 console.log("cr_users/"+user.uid)
                              
                                 this._AngularFirestore.doc("cr_users/"+user.uid).snapshotChanges().subscribe(doc => {
                                   let  tmp = doc.payload.data()
                                     this._translateService.use((tmp["preferred_language"]+"").toLowerCase());
                                 })
                            }
                         })
                    } else {
                        this.fuseConfig.layout.style = "vertical-layout-1";
                        this.Gotopage = "login";
                        console.log(" not  loged  ");
                        this._fuseSplashScreenService.hide();
                    }
                });
            }
            if (this._platform.ANDROID || this._platform.IOS) {
                this.document.body.classList.add("is-mobile");
            }

            // Set the private defaults
            this._unsubscribeAll = new Subject();
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // this._fuseSplashScreenService.show();

        /*  setTimeout(() => {
            console.log('HIDE')
            this._fuseSplashScreenService.hide();
        }, 3000); */
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(config => {
                this.fuseConfig = config;
                if (this.fuseConfig.layout.width === "boxed") {
                    this.document.body.classList.add("boxed");
                } else {
                    this.document.body.classList.remove("boxed");
                }
            });

            SHAREDCONSTServiceTsService.pageschange.asObservable().subscribe(data=>{
                console.log("OK DATA =>" + data)
                if(data == "restpass" ){
                    this.Gotopage ='forget-password'

                }else if (data == "register"){
                    this.Gotopage ='register'
                }
                else if (data == "login"){
                    this.Gotopage ='login'
                }
            })
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    b64DecodeUnicode(str) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(
            atob(str)
                .split("")
                .map(function (c) {
                    return (
                        "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                    );
                })
                .join("")
        );
    }
    async goHOME(user) {

        SHAREDCONSTServiceTsService.UserTocken = await user
            .getIdToken()
            .then(data => {
                localStorage.setItem("tk", data);
                return data;
            });
        let payload = JSON.parse(
            this.b64DecodeUnicode(
                SHAREDCONSTServiceTsService.UserTocken.split(".")[1]
            )
        );
        if (payload["role_manager"]) {
            if (payload["role_manager"] == true) {
                SHAREDCONSTServiceTsService.manager_tw_user_id =
                    payload["manager_tw_user_id"];
                this.fuseConfig.layout.style = "vertical-layout-1";
                //this.mainView.nativeElement.style.direction ="RTL"
                this.ngOnInit();
                console.log("  loged  ");
                setTimeout(() => {
                    this.Gotopage = "main";
                    this.router.navigate([
                        "/cards%R=" + SHAREDCONSTServiceTsService.UserTocken
                    ]);
                    this._fuseSplashScreenService.hide();
                }, 500);
            }
            if (payload["role_merchant"] == true) {
                console.log("Is manager ");
                
                this._fuseSplashScreenService.hide();

             //  this.afAuth.auth.signOut();
            }
        } else {
            console.log("Not Active ");
            this._fuseSplashScreenService.hide();
            this.Gotopage ='Active'
        }
    }
}
