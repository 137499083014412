import { Injectable, NgZone } from "@angular/core";

import { auth } from "firebase/app";
import { AngularFireAuth } from "@angular/fire/auth";
import {
    AngularFirestore
} from "@angular/fire/firestore";

import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { SHAREDCONSTServiceTsService } from "./sharedconstservice.ts.service";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
@Injectable({
    providedIn: "root"
})
export class AuthTsService {
    userData: any; // Save logged in user data
    constructor(
        private _httpClient: HttpClient, public snackBar: MatSnackBar,private _translateService :TranslateService,
        private SHAREDCONSTServiceTsServiceimp: SHAREDCONSTServiceTsService,
        public afs: AngularFirestore, // Inject Firestore service
        public afAuth: AngularFireAuth, // Inject Firebase auth service
        public router: Router,
        public ngZone: NgZone // NgZone service to remove outside scope warning
    ) {
        /* Saving user data in localstorage when 
    logged in and setting up null when logged out */

    }
    // Sign in with email/password
    SignIn(email, password) {
        return this.afAuth.auth
            .signInWithEmailAndPassword(email, password)
            .then(result => {

            })
            .catch(error => {
                window.alert(error.message);
            });
    }
    // Sign up with email/password
    SignUp(email, password, user) {
        return this.afAuth.auth
            .createUserWithEmailAndPassword(email, password)
            .then(result => {
                /* Call the SendVerificaitonMail() function when new user sign 
    up and returns promise */
                //  this.SendVerificationMail();
                console.log('Inscription')
                this.afs.doc("cr_users/" + result.user.uid).set(user).then(()=>{
                    console.log("OK")
                });
            })
            .catch(error => {
                window.alert(error.message);
            });
    }
    // Send email verfificaiton when new user sign up
    SendVerificationMail() {
        return this.afAuth.auth.currentUser.sendEmailVerification().then(() => {
            this.router.navigate(["verify-email-address"]);
        });
    }
    // Reset Forggot password
    ForgotPassword(passwordResetEmail) {
        return this.afAuth.auth
            .sendPasswordResetEmail(passwordResetEmail)
            .then(() => {
                window.alert("Password reset email sent, check your inbox.");
            })
            .catch(error => {
                window.alert(error);
            });
    }
    // Returns true when user is looged in and email is verified
    get isLoggedIn(): boolean {
        const user = JSON.parse(localStorage.getItem("user"));
        return user !== null && user.emailVerified !== false ? true : false;
    }
    // Sign in with Google
    GoogleAuth() {
        return this.AuthLogin(new auth.GoogleAuthProvider());
    }
    // Auth logic to run auth providers
    AuthLogin(provider) {
        return this.afAuth.auth
            .signInWithPopup(provider)
            .then(result => {
                this.ngZone.run(() => {
                    this.router.navigate(["dashboard"]);
                });
            })
            .catch(error => {
                window.alert(error);
            });
    }

    // Sign out
    SignOut() {
        return this.afAuth.auth.signOut().then(() => {
            this._translateService.use("fr");
        });
    }


    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    activateuser(email, pass) {

        var headerstmp = {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + SHAREDCONSTServiceTsService.UserTocken
        };
        console.log(headerstmp);
        return new Promise((resolve, reject) => {
            this._httpClient
                .post<any>(
                    SHAREDCONSTServiceTsService.BASE_URL + 'managers/authenticate',
                    {
                        login: email,
                        password: pass
                    },
                    {
                        headers: headerstmp
                    }
                )
                .subscribe((response: any) => {
                    this.SignOut()
                }, reject => {
                    this.snackBar.open(reject.error.message, 'X', {
                        duration: 4000,
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                    });
                });
        });

    }

}
