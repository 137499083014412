import { FuseNavigation } from '@fuse/types';

export  const navigation: FuseNavigation[] = [
    {
        id       : 'cards',
        title    : 'Card Services ',
        translate: 'STRINGS_cards',
        type     : 'group',
        children : [
            {
                id       : 'list_card',
                title    : 'list of cards',
                translate: 'STRINGS_lcards',
                type     : 'item',
                icon     : 'credit_card',
                url      : '/cards',
            }
        ]
    }, 
    {
        id       : 'docs',
        title    : 'dcos ',
        translate: 'STRINGS_docs',
        type     : 'group',
        children : [
            {
                id       : 'list_card',
                title    : 'list of cards',
                translate: 'STRINGS_lcards',
                type     : 'item',
                icon     : 'credit_card',
                url      : '/cards',
            }
        ]
    },
    {
        id       : 'tags',
        title    : 'Tags Services',
        translate: 'STRINGS_tank_tag',
        type     : 'group',
        children : [
            {
                id       : 'list_tag',
                title    : 'List OF tags',
                translate: 'STRINGS_tag_list',
                type     : 'item',
                icon     : 'nfc',
                url      : '/tags',
            },
            {
                id       : 'list_station',
                title    : 'List OF st',
                translate: 'STRINGS_lstations',
                type     : 'item',
                icon     : 'nfc',
                url      : '/sts',
            }
        ]
    }
];
