import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
    selector: 'app-stationdetail',
    templateUrl: './stationdetail.component.html',
    styleUrls: ['./stationdetail.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class StationdetailComponent implements OnInit {
    name: ""
    product: String[] = []
    constructor(public matDialogRef: MatDialogRef<StationdetailComponent>, public afAuth: AngularFireAuth, private _Fire: AngularFirestore,
        @Inject(MAT_DIALOG_DATA) private _data: any) {
        this.name = this._data.name;
        this._Fire.collection("cr_contracts", res => res.where('accountHolderCode', '==', parseInt(_data.accountHolderCode))).valueChanges().subscribe(d => {
            if (d) {
                d.forEach(db => {
                    this._Fire.collection("cr_contract_article", res => res.where('twContractCode', '==', parseInt(db['contract_code']))).
                        valueChanges().subscribe(d3 => {
                            if (d3) {
                                d3.forEach(db3 => {
                                    this._Fire.collection("cr_article", res => res.where('code', '==', parseInt(db3['twArticleCode']))).
                                        valueChanges().subscribe(d2 => {
                                            if (d2) {
                                                d2.forEach(db2 => {
                                                    console.log(db)
                                                    this.product.push("* : " + db2['name'] + "- " + db3['unitPrice'] + " MAD")
                                                })
                                            }
                                        });



                                })
                            }
                        });
                })
            }
        });
    }

    ngOnInit() {

    }

}
