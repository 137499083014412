import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { TranslatePipe } from "@ngx-translate/core";


import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { AuthTsService } from 'app/services/auth.service.ts.service';
import { Router } from '@angular/router';
import { MatDialog, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar } from '@angular/material';
import { MailComposeDialogComponent } from 'app/ADD/compose/compose.component';
import { FormGroup } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { mailService } from 'app/ADD/compose/products.service';
import { WaitspinerComponent } from 'app/ADD/spinerload/waitspiner/waitspiner.component';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})

export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    AuthTsServiceImp: AuthTsService;
    USER = ''
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private AuthTsService: AuthTsService, public dialog: MatDialog, private trnslate: TranslateService,
        private _fuseConfigService: FuseConfigService, public afAuth: AngularFireAuth, public snackBar: MatSnackBar,
        private _AngularFirestore: AngularFirestore, public mailService: mailService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService, public _matDialog: MatDialog
        ,
        private router: Router
    ) {





        this._AngularFirestore.collection('cr_user_account_holder', ref => ref.where('cr_user_uid', '==', afAuth.auth.currentUser.uid)).valueChanges().subscribe(res2 => {


            res2.forEach(tmp => {
                this._AngularFirestore.collection('cr_account_holders', ref => ref.where('id', '==', tmp["cr_account_hid"])).valueChanges().subscribe(res => {


                    res.forEach(tmp => {
                        this.USER = tmp['tw_account_holder_name'];
                    });

                });

            });

        });




        // Set the defaults
        this.AuthTsService = AuthTsService;
        this.userStatusOptions = [
            {
                'title': 'Online',
                'icon': 'icon-checkbox-marked-circle',
                'color': '#4CAF50'
            },
            {
                'title': 'Away',
                'icon': 'icon-clock',
                'color': '#FFC107'
            },
            {
                'title': 'Do not Disturb',
                'icon': 'icon-minus-circle',
                'color': '#F44336'
            },
            {
                'title': 'Invisible',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#BDBDBD'
            },
            {
                'title': 'Offline',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#616161'
            }
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'ar',
                title: 'Arabic',
                flag: 'ar'
            },
            {
                id: 'fr',
                title: 'Français',
                flag: 'fr'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { 'id': this._translateService.currentLang });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
        this.updateUser((lang.id + "").toUpperCase())
    }

    SignOut() {
        this.AuthTsService.SignOut()
    }
    GOedit() {
        console.log('GO')
        this.router.navigate(['/edit'])
    }
    updateUser(lang) {
        this.afAuth.authState.subscribe(user => {
            if (user) {

                this._AngularFirestore.doc("cr_users/" + user.uid).update({ preferred_language: lang })
            }
        })
    }
    dialogRef: any;
    opendialog() {
        this.dialogRef = this.dialog.open(WaitspinerComponent, {
        });

        this.dialogRef.afterClosed().subscribe(result => {

        });
        this.dialogRef.disableClose = true;
    }
    closedialog() {
        if (this.dialogRef != undefined && this.dialogRef != null)
            this.dialogRef.close()
    }

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    composeDialog(): void {
        this.dialogRef = this._matDialog.open(MailComposeDialogComponent, {
            panelClass: 'mail-compose-dialog'
        });
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const formData: FormGroup = response[1];
                switch (actionType) {
                    /**
                     * Send
                     */
                    case 'send':
                        this._AngularFirestore.doc("cr_users/" + this.afAuth.auth.currentUser.uid).snapshotChanges().subscribe(data => {
                            this.opendialog()
                            let tmp = data.payload.data()
                            this.mailService.SendMail({ displayName: tmp["first_name"] + tmp["last_name"], email: this.afAuth.auth.currentUser.email }, formData.controls.subject.value, formData.controls.message.value);
                            this.mailService.onSendpin.subscribe(data => {
                                if (data != null && data != undefined && data != "") {
                                    console.log(data)
                                    if (data == "OK") {
                                        this.trnslate.get('STRINGS_pin_ok').subscribe(data => {
                                            this.snackBar.open(data, 'X', {
                                                duration: 1000,
                                                horizontalPosition: this.horizontalPosition,
                                                verticalPosition: this.verticalPosition,
                                            });
                                            this.mailService.onSendpin = new BehaviorSubject("");
                                        })

                                    } else {
                                        this.trnslate.get('STRINGS_err_002').subscribe(data => {
                                            this.snackBar.open(data, 'X', {
                                                duration: 1000,
                                                horizontalPosition: this.horizontalPosition,
                                                verticalPosition: this.verticalPosition,
                                            });
                                            this.mailService.onSendpin = new BehaviorSubject("");
                                        })

                                    }
                                    this.closedialog()
                                }
                            })
                        })

                        break;
                    /**
                     * Delete
                     */
                    case 'delete':
                        console.log('delete Mail');
                        break;
                }
            });
    }


}
