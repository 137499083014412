import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { AuthTsService } from 'app/services/auth.service.ts.service';

@Component({
  selector: 'app-activateuser',
  templateUrl: './activateuser.component.html',
  styleUrls: ['./activateuser.component.scss']
})
export class ActivateuserComponent implements OnInit
{
    loginForm: FormGroup;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,public AuthTsServiceImp :AuthTsService
    )
    {
        // Configure the layout
        
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.loginForm = this._formBuilder.group({
            email   : ['',Validators.required],
            password: ['', Validators.required]
        });
    }


    activateuser(){
        this.AuthTsServiceImp.activateuser(this.loginForm.controls.email.value , this.loginForm.controls.password.value );
    }
}
