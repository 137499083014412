import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { StationdetailComponent } from 'app/ADD/stationdetail/stationdetail.component';
import { MatDialog } from '@angular/material';

@Component({

    selector: 'app-liststations',
    templateUrl: './liststations.component.html',
    styleUrls: ['./liststations.component.scss']
})
export class ListstationsComponent implements OnInit {

    constructor(private _Angfir: AngularFirestore,public dialog: MatDialog) {
        this.getlist()
    }

    ngOnInit() {
    }
    marker: Object[] = []
    getlist() {
        // db.collection("cr_account_holders")
        // .where("twAccountHoldeTypeCode", "==", 13)
        // .get()
        // .then(function(querySnapshot) {

        this._Angfir.collection("cr_account_holders", ref => ref.where('twAccountHoldeTypeCode', '==', 13)
        ).valueChanges().subscribe(data => {

            data.forEach(tmp => {
                this._Angfir.collection("cr_corporate", ref => ref.where("code", "==", parseInt(tmp["twCorporateCode"]))).valueChanges().subscribe(corpotatedate => {
                    corpotatedate.forEach(tmp2 => {
                        if (tmp2['latitude'] && tmp2["langitude"]) {

                            let d = {

                                langitude: parseFloat(tmp2["langitude"]), latitude: parseFloat(tmp2['latitude']), icons: "", id :tmp["tw_account_holder_code"],  name: tmp["tw_account_holder_name"]
                            }
                            this.marker.push(d);
                        }
                    })
                })

            })

        })
    }
    dialogRef: any;

    showproduct(I, name  ){
        console.log(I)
        this.dialogRef = this.dialog.open(StationdetailComponent, {
            panelClass: 'mail-compose-dialog' , data: {accountHolderCode:I, name : name  }
        });
    }

}
