import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { Subject } from 'rxjs';
import { TransactionProductsService } from './products.service';
import { RemoteConfigService } from 'app/services/remote-config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute } from '@angular/router';
import { ngxCsv } from 'ngx-csv';
import { AngularFirestore } from '@angular/fire/firestore';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { DateComponent } from 'app/ADD/date/date.component';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-list-transaction',
    templateUrl: './list-transaction.component.html',
    styleUrls: ['./list-transaction.component.scss'], animations: fuseAnimations
})
export class ListTransactionComponent implements OnInit {
    dataSource: MatTableDataSource<any>;
    displayedColumns = [
        "rrn", "station", "corporatname",
        "user", "dateSimple",
        "amount", "currency",
        "service_mean"
    ];
    @ViewChild("pag") pagina: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild("filter") filter: ElementRef;

    is_filtertrue = false; 



    currency_symbols = {
        '704': 'VND',
        '705': 'SIT',
        '706': 'SOS',
        '710': 'ZAR',
        '716': 'ZWD',
        '724': 'ESP',
        '736': 'SDD',
        '737': 'SDA',
        '740': 'SRG',
        '748': 'SZL',
        '752': 'SEK',
        '756': 'CHF',
        '760': 'SYP',
        '762': 'TJS',
        '764': 'THB',
        '776': 'TOP',
        '780': 'TTD',
        '784': 'AED',
        '788': 'TND',
        '792': 'TRL',
        '795': 'TMM',
        '800': 'UGX',
        '807': 'MKD',
        '810': 'RUR',
        '818': 'EGP',
        '826': 'GBP',
        '834': 'TZS',
        '840': 'USD',
        '858': 'UYU',
        '860': 'UZS',
        '862': 'VEB',
        '882': 'WST',
        '886': 'YER',
        '891': 'YUM',
        '894': 'ZMK',
        '901': 'TWD',
        '950': 'XAF',
        '951': 'XCD',
        '952': 'XOF',
        '953': 'XPF',
        '954': 'XEU',
        '974': 'BYR',
        '975': 'BGN',
        '976': 'CDF',
        '977': 'BAM',
        '978': 'EUR',
        '980': 'UAH',
        '981': 'GEL',
        '985': 'PLN',
        '986': 'BRL',
        '004': 'AFA',
        '008': 'ALL',
        '012': 'DZD',
        '024': 'AON',
        '031': 'AZM',
        '032': 'ARS',
        '036': 'AUD',
        '040': 'ATS',
        '044': 'BSD',
        '048': 'BHD',
        '050': 'BDT',
        '051': 'AMD',
        '052': 'BBD',
        '056': 'BEF',
        '060': 'BMD',
        '068': 'BOB',
        '070': 'BAD',
        '072': 'BWP',
        '084': 'BZD',
        '090': 'SBD',
        '096': 'BND',
        '104': 'MMK',
        '108': 'BIF',
        '116': 'KHR',
        '124': 'CAD',
        '132': 'CVE',
        '136': 'KYD',
        '144': 'LKR',
        '152': 'CLP',
        '156': 'CNY',
        '170': 'COP',
        '174': 'KMF',
        '188': 'CRC',
        '191': 'HRK',
        '192': 'CUP',
        '196': 'CYP',
        '203': 'CZK',
        '208': 'DKK',
        '214': 'DOP',
        '222': 'SVC',
        '230': 'ETB',
        '232': 'ERN',
        '233': 'EEK',
        '238': 'FKP',
        '242': 'FJD',
        '246': 'FIM',
        '250': 'FRF',
        '262': 'DJF',
        '270': 'GMD',
        '280': 'DEM',
        '288': 'GHC',
        '292': 'GIP',
        '300': 'GRD',
        '320': 'GTQ',
        '324': 'GNF',
        '328': 'GYD',
        '332': 'HTG',
        '340': 'HNL',
        '344': 'HKD',
        '348': 'HUF',
        '352': 'ISK',
        '356': 'INR',
        '360': 'IDR',
        '364': 'IRR',
        '365': 'IRA',
        '368': 'IQD',
        '372': 'IEP',
        '376': 'ILS',
        '380': 'ITL',
        '388': 'JMD',
        '392': 'JPY',
        '398': 'KZT',
        '400': 'JOD',
        '404': 'KES',
        '408': 'KPW',
        '414': 'KWD',
        '417': 'KGS',
        '418': 'LAK',
        '422': 'LBP',
        '428': 'LVL',
        '430': 'LRD',
        '434': 'LYD',
        '440': 'LTL',
        '442': 'LUF',
        '446': 'MOP',
        '450': 'MGF',
        '454': 'MWK',
        '458': 'MYR',
        '462': 'MVR',
        '470': 'MTL',
        '478': 'MRO',
        '480': 'MUR',
        '484': 'MXN',
        '496': 'MNT',
        '498': 'MDL',
        '504': 'MAD',
        '508': 'MZM',
        '512': 'OMR',
        '516': 'NAD',
        '524': 'NPR',
        '528': 'NLG',
        '532': 'ANG',
        '533': 'AWG',
        '548': 'VUV',
        '554': 'NZD',
        '558': 'NIO',
        '566': 'NGN',
        '578': 'NOK',
        '586': 'PKR',
        '590': 'PAB',
        '598': 'PGK',
        '600': 'PYG',
        '604': 'PEN',
        '608': 'PHP',
        '620': 'PTE',
        '624': 'GWP',
        '626': 'TPE',
        '634': 'QAR',
        '642': 'ROL',
        '643': 'RUB',
        '646': 'RWF',
        '654': 'SHP',
        '678': 'STD',
        '682': 'SAR',
        '690': 'SCR',
        '694': 'SLL',
        '702': 'SGD',
        '703': 'SKK',
        '410': 'KRW',
        '973': 'AOA',
        '112': 'BYB',
        '948': 'CHW',
        '158': 'CNX',
        '970': 'COU',
        '278': 'DDM',
        '218': 'ECS',
        '936': 'GHS',
        '426': 'LSL',
        '969': 'MGA',
        '943': 'MZN',
        '616': 'PLZ',
        '999': 'PNT',
        '793': 'PTL',
        '946': 'RON',
        '941': 'RSD',
        '968': 'SRD',
        '949': 'TRY',
        '937': 'VEF',
        '720': 'YDD',
        '180': 'ZRN',
        '76': 'BRC',
        '64': 'BTN'
    };
    // Private

    private _unsubscribeAll: Subject<any>;
    datafotable = [];
    MASK ="" ;
    constructor(public _ProductsService: TransactionProductsService, public route: ActivatedRoute, public _matDialog: MatDialog, public Angulafir: AngularFirestore) {
        console.log(decodeURIComponent(this.route.snapshot.url.toString()).toString().split(",")[1]) 
        _ProductsService.getTransaction(decodeURIComponent(this.route.snapshot.url.toString()).toString().split(",")[1]) ////this.route.snapshot.paramMap.get('id'));
        this.MASK = this.route.snapshot.paramMap.get("mask");
        _ProductsService.onProductsChanged.subscribe(data => {
            if (data != null && data != undefined && data.rrn != undefined) {
                //console.log(data.rrn)
                if (this.datafotable.indexOf(data) > -1) {
                    return;
                }
                data.station = "...";
                if (data.merchant_id)
                this.Angulafir.collection("cr_corporate/", ref => ref.where('id', '==', data.merchant_id)).valueChanges().subscribe(res => {
                    res.forEach(tmp => {
                        // console.log(data.rrn +" -> " + tmp["tw_account_holder_name"])
                        data.station = tmp["name"];
                    })
                    if (res.length == 0) {
                        this.Angulafir.collection("cr_account_holders/", ref => ref.where('id', '==', data.merchant_id)).valueChanges().subscribe(res2 => {
                            console.log(res2)
                            res2.forEach(tmp2 => {
                                // console.log(data.rrn +" -> " + tmp2["tw_account_holder_name"])
                                data.station = tmp2["tw_account_holder_name"];
                            })
                        });
                    }
                });

                this.Angulafir.doc("cr_cards/" + decodeURIComponent(this.route.snapshot.url.toString()).toString().split(",")[1]).valueChanges().subscribe(res2 => {
                    data.user = res2["nameOnCard"];

                    if (res2["cr_account_holder"])
                    this.Angulafir.doc("cr_account_holders/" + res2["cr_account_holder"]).valueChanges().subscribe(res3 => {
                        //data.user = res3["nameOnCard"]; 

                        data.corporatname = res3["tw_account_holder_name"]

                    });

                });
                this.datafotable.push(data)
                this.dataSource = new MatTableDataSource(this.datafotable);
                this.dataSource.paginator = this.pagina;
                this.dataSource.sort = this.sort;
                this.dataSource.connect().subscribe(d => this.renderedData = d);
                
            }
        });

        this._unsubscribeAll = new Subject();
        this.dataSource = new MatTableDataSource([]);
        this._unsubscribeAll.subscribe()

    }

    isactive(tmp: String) {
        return (RemoteConfigService.ACTIVE_SERVICE.indexOf(tmp) > -1)
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    getdate(long) {
        return new Date(long).getDate.toString
    }

    ngOnInit() {

    }
    private renderedData: any;
    private renderedDatafliter: any;


    CSV() {
        let data = []
        let tmpdata = {};
        tmpdata['rrn'] = "RRN"
        tmpdata['station'] = "Station name"
        tmpdata['corporatname'] = "Company name"
        tmpdata['service_mean'] = "Service mean"
        tmpdata['amount'] = "Amount"
        tmpdata['dateSimple'] = "Date"
        tmpdata['currency'] = "Currency"
        tmpdata['user'] = "name on Card"
        // data[key] = element[key];
        // data[key] = element[key];"station", "corporatname", 
        data.push(tmpdata)
        //JSON.parse(JSON.stringify()
        if (this.is_filtertrue ==false){
            
            this.renderedData.forEach(element => {

                let tmpdata = {};
                tmpdata['rrn'] = element["rrn"];
                tmpdata['station'] = element["station"];
                tmpdata['corporatname'] = element['corporatname'];
                tmpdata['service_mean'] = element['service_mean'];
                tmpdata['amount'] = element["amount"];
                tmpdata['dateSimple'] = element['dateSimple'];
                tmpdata['currency'] = element["currency"];
                tmpdata['user'] = element['user'];
                // data[key] = element[key];
                // data[key] = element[key];"station", "corporatname", 
    
                data.push(tmpdata);
            });
        }
            else {
                this.renderedDatafliter.forEach(element => {

                    let tmpdata = {};
                    tmpdata['rrn'] = element["rrn"];
                    tmpdata['station'] = element["station"];
                    tmpdata['corporatname'] = element['corporatname'];
                    tmpdata['service_mean'] = element['service_mean'];
                    tmpdata['amount'] = element["amount"];
                    tmpdata['dateSimple'] = element['dateSimple'];
                    tmpdata['currency'] = element["currency"];
                    tmpdata['user'] = element['user'];
                    // data[key] = element[key];
                    // data[key] = element[key];"station", "corporatname", 
        
                    data.push(tmpdata);
                });
            }

        new ngxCsv(data, 'TrX+' + new Date().toDateString());
    }

    getPDF() {
        const doc = new jsPDF({
            orientation: 'landscape'
        }
        );
        doc.setFontSize(12);
        var head = [["RRN", "Station name", "Company name", "name on Card", "Service mean", "Amount", "Date", "Currency"]];
        var body = [
        ];
        if (this.is_filtertrue ==false){
        this.renderedData.forEach(element => {
            body.push(
                [element["rrn"],
                element["station"],
                element['corporatname'],
                element['user'],
                element['service_mean'],
                element["amount"],
                element['dateSimple'],this.currency_symbols[element["currency"]]]);
        });}
        else {
            this.renderedDatafliter.forEach(element => {
                body.push(
                    [element["rrn"],
                    element["station"],
                    element['corporatname'],
                    element['user'],
                    element['service_mean'],
                    element["amount"],
                    element['dateSimple'],this.currency_symbols[element["currency"]]]);
            });
        }
        doc.text( 20, 10,"List of transactions");
        doc.text( 20, 15 , new Date().toDateString() + ' - ' + new Date().toTimeString());
        if (this.is_filtertrue){doc.text(20, 20,  'From ' +this.searchvalue);}
        doc.setTextColor(150);
        doc.setTextColor('blue');
        doc.text(20, 25,"Card : " + this.MASK);
        doc.setTextColor('black');
        doc.autoTable({ head: head, body: body  ,margin: {top: 30}});
        doc.save('TrX+' + new Date().toDateString() + '.pdf');
    }





    dialogRefctc2: any
    searchvalue: any
    getbetwinedate() {
        this.dialogRefctc2 = this._matDialog.open(DateComponent, {
            panelClass: 'mail-compose-dialog'
        });
        this.dialogRefctc2.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const formData: FormGroup = response[1];
                switch (actionType) {
                    /**
                     * Send
                     */
                    case 'send':
                        console.log('new', formData.getRawValue()['from'].valueOf());
                        this.filter2date(formData.getRawValue()['from'].valueOf(), formData.getRawValue()['to'].valueOf());
                        this.searchvalue = (formData.getRawValue()['from'].format("YYYY-MM-DD") + " To " + formData.getRawValue()['to'].format("YYYY-MM-DD"));
                        this.is_filtertrue =true ;
                        break;
                    /**
                     * Delete
                     */
                    case 'delete':
                        this.dataSource = new MatTableDataSource(this.renderedData);
                        this.dataSource.paginator = this.pagina;
                        this.dataSource.sort = this.sort;
                        this.searchvalue = ("");
                        this.is_filtertrue = false ;

                        break;
                }
            });
    }


    filter2date(d1, d2) {
        let isd = true;
        let tmpdata = [];
        this.renderedData.forEach(element => {
            console.log(element['date'] + " " + d1 + " " + d2);
            if ((d1 - 1) < element['date']) {
                if (element['date'] < (d2 + 1)) {
                    isd = false;
                    tmpdata.push(element);
                }
            }
        });
        if (!isd) {
            this.dataSource = new MatTableDataSource(tmpdata);
            this.dataSource.paginator = this.pagina;
            this.dataSource.sort = this.sort;
            this.dataSource.connect().subscribe(d => this.renderedDatafliter = d);
            
        }

    }

}
