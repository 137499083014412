import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators, NgModel } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthTsService } from 'app/services/auth.service.ts.service';
import { SHAREDCONSTServiceTsService } from 'app/services/sharedconstservice.ts.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    animations: fuseAnimations
})
export class RegisterComponent implements OnInit, OnDestroy {
    registerForm: FormGroup;

    // Private
    private _unsubscribeAll: Subject<any>;
    languages = [
        {
            id: 'en',
            title: 'English',
            flag: 'us'
        },
        {
            id: 'ar',
            title: 'Arabic',
            flag: 'ar'
        },
        {
            id: 'fr',
            title: 'Françai',
            flag: 'fr'
        }
    ];
    selectedLanguage: any;
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private router: Router,
        private _serviceregister: AuthTsService,
        private _translateService: TranslateService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        this.selectedLanguage = _.find(this.languages, { 'id': this._translateService.currentLang });

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    /**
    * Set the language
    *
    * @param lang
    */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */

    ngOnInit(): void {
        this.registerForm = this._formBuilder.group({
            name: ['', Validators.required],
            num: ['', Validators.required],
            lname: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
        });
        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.registerForm.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.registerForm.get('passwordConfirm').updateValueAndValidity();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    register() {
        let user = {
            email: this.registerForm.controls.email.value,
            first_name: this.registerForm.controls.name.value,
            last_name: this.registerForm.controls.lname.value,
            mobile: this.registerForm.controls.num.value,
            is_Merchant: null,
            is_driver: false,
            is_manager: null,
            issuing_country: "MA",
            preferred_language: (this.selectedLanguage.id + "").toUpperCase()
        }
        this._serviceregister.SignUp(user.email, this.registerForm.controls.passwordConfirm.value, user)
    }


    logint() {
        SHAREDCONSTServiceTsService.pageschange.next("login");
    }

}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
        return null;
    }

    if (passwordConfirm.value === '') {
        return null;
    }

    if (password.value === passwordConfirm.value) {
        return null;
    }

    return { 'passwordsNotMatching': true };
};
