import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, MinLengthValidator } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    animations: [fuseAnimations]
})
export class ProfileComponent implements OnInit, OnDestroy {
    form: FormGroup;
    formErrors: any;
    formpassword: FormGroup;
    // Horizontal Stepper

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _formBuilder: FormBuilder, private _AngularFirestore: AngularFirestore, private afAuth: AngularFireAuth

    ) {
        // Reactive form errors
        this.formErrors = {
            company: {},
            firstName: {},
            lastName: {},
            address: {},
            address2: {},
            city: {},
            state: {},
            postalCode: {},
            country: {}
        };

      


        this.form = this._formBuilder.group({
            email: [
                {
                    value: this.afAuth.auth.currentUser.email,
                    disabled: true
                }, Validators.required
            ],
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            phone: ['', Validators.required],
            lang: ['', Validators.required]
        });

        this._AngularFirestore.doc("cr_users/" + this.afAuth.auth.currentUser.uid).snapshotChanges().subscribe(data => {
            if ( data ){
                let  tmp = data.payload.data()
                this.form.controls.first_name.setValue ( (tmp["first_name"]+"").toLowerCase());
                this.form.controls.last_name.setValue ( (tmp["last_name"]+"").toLowerCase());
                this.form.controls.lang.setValue ( (tmp["preferred_language"]+"").toLowerCase());
                this.form.controls.phone.setValue ( (tmp["mobile"]+"").toLowerCase());

            }
         });


        this.formpassword = this._formBuilder.group({
            passcr: ['', Validators.required],
            passnew: ['', Validators.required ,Validators.minLength(7), Validators.maxLength(24)],
            passcon: ['', Validators.required ,Validators.minLength(7), Validators.maxLength(24)]
        });

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Reactive Form

        this.form.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.onFormValuesChanged();
            });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }





    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On form values changed
     */
    onFormValuesChanged(): void {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.formErrors[field] = {};

            // Get the control
            const control = this.form.get(field);

            if (control && control.dirty && !control.valid) {
                this.formErrors[field] = control.errors;
            }
        }
    }

    /**
     * Finish the horizontal stepper
     */

    SetUser() {
        let data = this.form.controls;
        let userdata = {
            last_name: data.last_name.value,
            first_name: data.first_name.value,
            mobile: data.phone.value,
            preferred_language: data.lang.value
        }
        this.updateUser(userdata);
    }

    updateUser(user) {

        try {
            // let  tmp = doc.payload.data()
            this._AngularFirestore.doc("cr_users/" + this.afAuth.auth.currentUser.uid).update(user)

        } catch (e) {

        }
    }
    isokpass() {
        return (this.formpassword.controls.passnew.value+"").toString() === (this.formpassword.controls.passcon.value+"").toString();
    }

    updatepass(){
        
        this.afAuth.auth.signInWithEmailAndPassword(this.afAuth.auth.currentUser.email, this.formpassword.controls.passcr.value)
        .then(result => {
            this.afAuth.auth.currentUser.updatePassword(this.formpassword.controls.passnew.value).then(()=>{
                this.afAuth.auth.signOut();
            })
        })
        .catch(error => {
            window.alert(error.message);
        });

    }
}
