import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { SHAREDCONSTServiceTsService } from "app/services/sharedconstservice.ts.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { resolve } from "url";
import { ThrowStmt } from "@angular/compiler";

@Injectable()
export class CardProductsService {
    products: any[];
    onProductsChanged: BehaviorSubject<any>;
    onSendpin: BehaviorSubject<any>;
    ondeactivatecard: BehaviorSubject<any>;
    oneactivatecard: BehaviorSubject<any>;
    onegetlimitcard: BehaviorSubject<any>;
    onegetbalancecard: BehaviorSubject<any>;
    oneCTCcard: BehaviorSubject<any>;
    oneInvo = new Subject<any>();
    oneStat = new Subject<any>();
    constructor(private _httpClient: HttpClient, private SHAREDCONSTServiceTsServiceimp: SHAREDCONSTServiceTsService, private afAuth: AngularFireAuth , private Fire :AngularFirestore
    ) {
        // Set the defaults
        this.onProductsChanged = new BehaviorSubject({});
        this.onSendpin = new BehaviorSubject("");
        this.ondeactivatecard = new BehaviorSubject({ data: "", message: "" });
        this.oneactivatecard = new BehaviorSubject({ data: "", message: "" });
        this.onegetlimitcard = new BehaviorSubject({ data: "", message: "" });
        this.onegetbalancecard = new BehaviorSubject({ data: "", message: "" });
        this.oneCTCcard = new BehaviorSubject({ data: "", message: "" });
        
    }


    tocken = ""
    getcards(): Promise<any> {
        var headerstmp = {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + SHAREDCONSTServiceTsService.UserTocken
        };
        console.log(headerstmp);
        return new Promise((resolve, reject) => {
            this._httpClient
                .post<any>(
                    SHAREDCONSTServiceTsService.BASE_URL + "cards/card-list",
                    { cardCode: "" },
                    {
                        headers: headerstmp
                    }
                )
                .subscribe((response: any) => {
                    console.log(JSON.stringify(response));
                    this.products = response;
                    this.onProductsChanged.next(this.products);
                    resolve(response);
                }, reject);
        });
    }
    sendpin(CardId) {
        var headerstmp = {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + SHAREDCONSTServiceTsService.UserTocken
        };
        console.log(headerstmp);
        return new Promise((resolve, reject) => {
            this._httpClient
                .post<any>(
                    SHAREDCONSTServiceTsService.BASE_URL + 'cards/' + CardId + '/send-pin',
                    { cardCode: "", isReset: false },
                    {
                        headers: headerstmp
                    }
                )
                .subscribe((response: any) => {
                    this.onSendpin.next("OK");
                }, reject => {
                    console.log(reject)
                    this.onSendpin.next(reject.error.message);
                });
        });
    }
    Activatecard(CardId) {
        var headerstmp = {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + SHAREDCONSTServiceTsService.UserTocken
        };
        return new Promise((resolve, reject) => {
            this._httpClient
                .post<any>(
                    SHAREDCONSTServiceTsService.BASE_URL + 'cards/' + CardId + '/activate',
                    { cardCode: "", isReset: false },
                    {
                        headers: headerstmp
                    }
                )
                .subscribe((response: any) => {
                    console.log(response)
                    this.oneactivatecard.next({ data: "OK", message: response.message });
                }, reject => {
                    console.log(reject)
                    this.oneactivatecard.next({ data: "KO", message: reject.error.message });
                });
        });
    }

    TranCTC(CardId, data){
        var headerstmp = {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + SHAREDCONSTServiceTsService.UserTocken
        };
        return new Promise((resolve, reject) => {
            this._httpClient
                .post<any>(
                    SHAREDCONSTServiceTsService.BASE_URL + 'cards/' + CardId + '/card-to-card-transfer',
                    data,
                    {
                        
                        headers: headerstmp

                    }
                )
                .subscribe((response: any) => {
                    console.log(response)
                    this.oneCTCcard.next({ data: "OK", message: response.message });
                }, reject => {
                    console.log(reject)
                    this.oneCTCcard.next({ data: "KO", message: reject.error.message });
                });
        });;
    }

    getInvo(transactionTable){
         

        this.afAuth.user.subscribe(user => {
            if (user){
                let UID =  user.uid
                this.Fire.collection("cr_user_account_holder" , (res =>res.where("cr_user_uid", "==", UID) ))
                .get()
                .subscribe( (querySnapshot)  => {
                   
                    
                    querySnapshot.forEach( (doc) => {
                      
                       this.Fire.collection("cr_account_holders", res  => res.where("id", "==",doc.data().cr_account_hid))
                            .get()
                            .subscribe( (querySnapshot) =>  {
                               
                                querySnapshot.forEach( (doc) =>{
                                    // doc.data() is never undefined for query doc snapshots
                                    console.log(doc.data());
   
                                    
                                    this.Fire.collection("cr_users_resources" , res => res.where("cr_account_holder_code", "==", doc.data().tw_account_holder_code.valueOf()))
                                            .get()
                                            .subscribe( (querySnapshot)  => {
                                               
                                                querySnapshot.forEach( (doc)=> {

                                                   
                                                    // doc.data() is never undefined for query doc snapshots
                                                    for (var i = 0; i < doc.data().cr_user_files.length; i++) {
                                                      try {
                                                            if (doc.data().cr_user_files[i].cr_user_file_root == "Invoices") {
                                                           
                                                                if (doc.data().cr_user_files[i].cr_user_file_path.split("/")[2].split("-").length  == 5) {
                                                                    var year = doc.data().cr_user_files[i].cr_user_file_path.split("/")[2].split("-")[1].substring(0, 4);
                                                                    var month = doc.data().cr_user_files[i].cr_user_file_path.split("/")[2].split("-")[1].substring(4, 6);
                                                                    var file = doc.data().cr_user_files[i].cr_user_file_path.split("/")[2].split("-")[4]
   
                                                                } else {
   
                                                                    var year = doc.data().cr_user_files[i].cr_user_file_path.split("/")[2].split("_")[2].substring(0, 4);
                                                                    var month = doc.data().cr_user_files[i].cr_user_file_path.split("/")[2].split("_")[2].substring(4, 6);
                                                                    var file = doc.data().cr_user_files[i].cr_user_file_path.split("/")[2].
                                                                    substring(doc.data().cr_user_files[i].cr_user_file_path.split("/")[2].
                                                                        indexOf(year + month) + 7, doc.data().cr_user_files[i].cr_user_file_path.
                                                                            split("/")[2].length);
                                                                }
                                                                let zz : any ;
                                                                zz  = doc.data().cr_user_files[i].cr_user_file_size / 1024;
                                                                if (zz < 1024) {
                                                                    zz = zz.toFixed(2) + " Ko"
   
                                                                } else {
                                                                    zz = (zz / 1024).toFixed(2) + " Mo"
                                                                }
   
                                                                transactionTable.push([
                                                                    file, year+"/"+month, doc.data().cr_user_files[i].cr_user_file_type, zz,  doc.data().cr_user_files[i].cr_user_file_path 
                                                                ]);
                                                                
                                                            }
   
                                                        } catch (Ex) {
                                                            console.error(Ex);
                                                        }
                                                           
                                                    }
                                                    
                                                   
                                                });
                                                this.notify(transactionTable)
                                            })
                                            
   
                                })
                            });
   
                    });
                })
            }
             
            
         })

         /*
            this.Fire.collection("").get().subscribe(res =>{
               
                    if (res.docs){
                        this.oneInvo.next(res.docs)
                    } 

            })*/

    }
    getStat(transactionTable){
         

        this.afAuth.user.subscribe(user => {
            if (user){
                let UID =  user.uid
                this.Fire.collection("cr_user_account_holder" , (res =>res.where("cr_user_uid", "==", UID) ))
                .get()
                .subscribe( (querySnapshot)  => {
                   
                    
                    querySnapshot.forEach( (doc) => {
                      
                       this.Fire.collection("cr_account_holders", res  => res.where("id", "==",doc.data().cr_account_hid))
                            .get()
                            .subscribe( (querySnapshot) =>  {
                               
                                querySnapshot.forEach( (doc) =>{
                                    // doc.data() is never undefined for query doc snapshots
                                    console.log(doc.data());
   
                                    
                                    this.Fire.collection("cr_users_resources" , res => res.where("cr_account_holder_code", "==", doc.data().tw_account_holder_code.valueOf()))
                                            .get()
                                            .subscribe( (querySnapshot)  => {
                                               
                                                querySnapshot.forEach( (doc)=> {
                                                   console.log(doc.data());
                                                    // doc.data() is never undefined for query doc snapshots
                                                    for (var i = 0; i < doc.data().cr_user_files.length; i++) {
                                                      try {
                                                            if (doc.data().cr_user_files[i].cr_user_file_root == "Statements") {
                                                           
                                                                if (doc.data().cr_user_files[i].cr_user_file_path.split("/")[2].split("-").length  == 5) {
                                                                    var year = doc.data().cr_user_files[i].cr_user_file_path.split("/")[2].split("-")[1].substring(0, 4);
                                                                    var month = doc.data().cr_user_files[i].cr_user_file_path.split("/")[2].split("-")[1].substring(4, 6);
                                                                    var file = doc.data().cr_user_files[i].cr_user_file_path.split("/")[2].split("-")[4]
   
                                                                } else {
   
                                                                    var year = doc.data().cr_user_files[i].cr_user_file_path.split("/")[2].split("_")[2].substring(0, 4);
                                                                    var month = doc.data().cr_user_files[i].cr_user_file_path.split("/")[2].split("_")[2].substring(4, 6);
                                                                    var file = doc.data().cr_user_files[i].cr_user_file_path.split("/")[2].
                                                                    substring(doc.data().cr_user_files[i].cr_user_file_path.split("/")[2].
                                                                        indexOf(year + month) + 7, doc.data().cr_user_files[i].cr_user_file_path.
                                                                            split("/")[2].length);
                                                                }
                                                                let zz : any ;
                                                                zz  = doc.data().cr_user_files[i].cr_user_file_size / 1024;
                                                                if (zz < 1024) {
                                                                    zz = zz.toFixed(2) + " Ko"
   
                                                                } else {
                                                                    zz = (zz / 1024).toFixed(2) + " Mo"
                                                                }
   
                                                                transactionTable.push([
                                                                    file, year+"/"+month, doc.data().cr_user_files[i].cr_user_file_type, zz,  doc.data().cr_user_files[i].cr_user_file_path 
                                                                ]);
                                                                
                                                            }
   
                                                        } catch (Ex) {
                                                            console.error(Ex);
                                                        }
                                                           
                                                    }
                                                    
                                                   
                                                });
                                                this.oneStat.next(transactionTable)

                                            })
                                            
   
                                })
                            });
   
                    });
                })
            }
             
            
         })

         /*
            this.Fire.collection("").get().subscribe(res =>{
               
                    if (res.docs){
                        this.oneInvo.next(res.docs)
                    } 

            })*/

    }

    notify(e){
        this.oneInvo.next(e)
    }


    deActivatecard(CardId) {
        var headerstmp = {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + SHAREDCONSTServiceTsService.UserTocken
        };
        return new Promise((resolve, reject) => {
            this._httpClient
                .post<any>(
                    SHAREDCONSTServiceTsService.BASE_URL + 'cards/' + CardId + '/deactivate',
                    { cardCode: "", isReset: false },
                    {
                        headers: headerstmp
                    }
                )
                .subscribe((response: any) => {
                    console.log(response)
                    this.ondeactivatecard.next({ data: "OK", message: response.message });
                }, reject => {
                    console.log(reject)
                    this.ondeactivatecard.next({ data: "KO", message: reject.error.message });
                });
        });;
    }
    getbalance(CardId) {
        var headerstmp = {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + SHAREDCONSTServiceTsService.UserTocken
        };
        return new Promise((resolve, reject) => {
            this._httpClient
                .post<any>(
                    SHAREDCONSTServiceTsService.BASE_URL + 'cards/' + CardId + '/limit-inquery',
                    {
                        isLimitInquiry: false,
                        isPrepaid: true
                    },
                    {
                        headers: headerstmp
                    }
                )
                .subscribe((response: any) => {
                    console.log(response)
                    this.onegetbalancecard.next({ data: "OK", message: response.message.balance });
                }, reject => {
                    console.log(reject)
                    this.onegetbalancecard.next({ data: reject.error.respCode, message: reject.error.message });
                });
        });;
    }






    getlimite(CardId) {
        var headerstmp = {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + SHAREDCONSTServiceTsService.UserTocken
        };
        return new Promise((resolve, reject) => {
            this._httpClient
                .post<any>(
                    SHAREDCONSTServiceTsService.BASE_URL + 'cards/' + CardId + '/limit-inquery',
                    { isLimitInquiry: true, isPrepaid: false },
                    {
                        headers: headerstmp
                    }
                )
                .subscribe((response: any) => {
                    console.log(response)
                    this.onegetlimitcard.next({ data: "OK", message: response.message.balance });
                }, reject => {
                    console.log(reject)
                    this.onegetlimitcard.next({ data: reject.error.respCode, message: reject.error.message });
                });
        });;
    }


    getstatuscard(S: String) {
        //LL
        if (S == "002") return "STRINGS_deactiv";
        if (S == "001") return "STRINGS_activ";
        //"activ"));
        if (S == "003") return "STRINGS_deactiv";
        //MyApp.StringsRepository.get("deactiv"));
        if (S == "004") return "STRINGS_canceled";
        //MyApp.StringsRepository.get("canceled") == null ? "Canceled" : MyApp.StringsRepository.get("canceled"));

    }



}
