import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
export interface DialogData {
    nameString: number;
    dataString: string;
}
@Component({
    selector: 'app-defaultdialog',
    templateUrl: './defaultdialog.component.html',
    styleUrls: ['./defaultdialog.component.scss']
})
export class DefaultdialogComponent implements OnInit {

    typemessage = 1
    message = ""
    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
        console.log(data)
        this.message = data.dataString
        this.typemessage = data.nameString
    }

    ngOnInit() {
    }

}
