import { Injectable } from "@angular/core";

import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { SHAREDCONSTServiceTsService } from "app/services/sharedconstservice.ts.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";



@Injectable()
export class TransactionProductsService {
    products: any[];
    onProductsChanged: BehaviorSubject<any>;
    constructor(private trnslate: TranslateService, public snackBar: MatSnackBar, private _httpClient: HttpClient, private _AngularFirestore: AngularFirestore, private SHAREDCONSTServiceTsServiceimp: SHAREDCONSTServiceTsService, private afAuth: AngularFireAuth
    ) {
        // Set the defaults
        this.onProductsChanged = new BehaviorSubject({});
    }


    tocken = ""
    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    getTransaction(id) {
        this._AngularFirestore.collection('cr_transactions', ref => ref.where('cr_transaction_card', '==', id)
            .orderBy("date", "desc")).valueChanges().subscribe(res2 => {
                res2.forEach(tmp => {
                    tmp['dateSimple'] = new Date(tmp['date']).toISOString().slice(0, 19).replace('T', ' '); //  new Date(tmp['date']).getFullYear() + "/" +(new Date(tmp['date']).getMonth()<10 ? "0" +new Date(tmp['date']).getMonth(): new Date(tmp['date']).getMonth()) +"/"+(new Date(tmp['date']).getDay()<10? + "0"+new Date(tmp['date']).getDay() :new Date(tmp['date']).getDay())
                    this.onProductsChanged.next(tmp);
                })
                if (res2.length == 0) {
                    this.trnslate.get('STRINGS_no_rez').subscribe(data => {
                        this.snackBar.open(data, 'X', {
                            duration: 1000,
                            horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,
                        });
                    })

                }
            });
    }


    getstatuscard(S: String) {
        if (S == "002") return "STRINGS_deactiv";
        if (S == "001") return "STRINGS_activ";//"activ"));
        if (S == "003") return "STRINGS_deactiv";//MyApp.StringsRepository.get("deactiv"));
        if (S == "004") return "STRINGS_canceled";//MyApp.StringsRepository.get("canceled") == null ? "Canceled" : MyApp.StringsRepository.get("canceled"));
    }
















}
