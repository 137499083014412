import { Injectable } from '@angular/core';
import { stat } from 'fs';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SHAREDCONSTServiceTsService {
    static UserTocken = ""
    static user = "" 
    static manager_tw_user_id = ""
    static BASE_URL = "https://us-central1-cr-sasco-prod.cloudfunctions.net/v1/";
    static pageschange = new BehaviorSubject('');
    static currency_code_symbols = {
        '950': 'XAF',
        '504': 'MAD',
        '952': 'XOF',
        '788': 'TND',
        '400': 'JOD',
        '840': 'USD',
        '404': 'KES',
        '682': 'SAR'
    };

    static currency_symbols_code = {
        'XAF': '950',
        'MAD': '504',
        'XOF': '952',
        'TND': '788',
        'JOD': '400',
        'USD': '840',
        'KES': '404',
        'SAR': '682'
    };




    constructor(public afAuth: AngularFireAuth) {
        this.afAuth.authState.subscribe(user => {
            if (user) {
                user.getIdToken().then(tk => {
                    SHAREDCONSTServiceTsService.UserTocken = tk

                })
            }
        });
    }




}
