import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { SampleComponent } from './sample.component';
import { CardListComponent } from '../card/card-list/card-list.component';
import { MatPaginatorModule } from '@angular/material';
import { AppComponent } from 'app/app.component';
import { ListTagsComponent } from '../tags/list-tags/list-tags.component';
import { ListstationsComponent } from '../maps/liststations/liststations.component';
import { ListTransactionComponent } from '../card/list-transaction/list-transaction.component';
import { ProfileComponent } from '../user/profile/profile.component';
import { RegisterComponent } from '../register/register.component';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';
import { ActivateuserComponent } from '../activateuser/activateuser.component';
import { CardCotractComponent } from '../card-cotract/card-cotract.component';
import { InvoicesComponent } from '../card/fct/invoices/invoices.component';
import { StatmentsComponent } from '../card/fct/statments/statments.component';

const routes = [
    { path: 'cards', component: CardListComponent },
    { path: 'tags', component: ListTagsComponent },
    { path: 'sts', component: ListstationsComponent },
    { path: 'settingCard/:id', component: CardCotractComponent },
    { path: 'trs/:id/:mask', component: ListTransactionComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'restpass', component: ForgetPasswordComponent },
    { path: 'activ', component: ActivateuserComponent },
    {
        path: 'edit',
        component: ProfileComponent
    },
    {
        path: 'Invo',
        component: InvoicesComponent
    }
    ,{
        path: 'Sts',
        component: StatmentsComponent
    }

];

@NgModule({
    declarations: [
        SampleComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatPaginatorModule,
        TranslateModule,

        FuseSharedModule
    ],
    exports: [
        SampleComponent
    ]
})

export class SampleModule {
}
