import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { DataSource } from "@angular/cdk/collections";
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";

import { fuseAnimations } from "@fuse/animations";
import { FuseUtils } from "@fuse/utils";

import { takeUntil } from "rxjs/internal/operators";
import { tagsProductsService } from "./products.service";

@Component({
  selector: 'app-list-tags',
  templateUrl: './list-tags.component.html',
  styleUrls: ['./list-tags.component.scss'],
  animations: fuseAnimations
})
export class ListTagsComponent implements OnInit {
    
    dataSource:MatTableDataSource<any>;
    displayedColumns = [
        "nameOnCard",
        "masked_pan",
        "is_prepaid",
        "activation_status","Action"
    ];
    @ViewChild("pag") pagina: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild("filter") filter: ElementRef;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(private _ProductsService: tagsProductsService) {
        // Set the private defaults
        _ProductsService
            .getcards()
            .then(data => {
                console.log(data)
                this.dataSource =  new MatTableDataSource(data["message"]);
                this.dataSource.paginator = this.pagina;
                this.dataSource.sort = this.sort;
                
            })
            .catch(err => {});
        this._unsubscribeAll = new Subject();
        this.dataSource = new MatTableDataSource([]);

       
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }
    ngOnInit() {
       
      }
}
