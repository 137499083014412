import { Component, ElementRef, OnInit, ViewChild, HostListener } from "@angular/core";
import {
    MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
    MatDialog,
} from "@angular/material";
import { DataSource } from "@angular/cdk/collections";
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { fuseAnimations } from "@fuse/animations";
import { FuseUtils } from "@fuse/utils";

import { takeUntil } from "rxjs/internal/operators";
import { CardProductsService } from "./products.service";
import { RemoteConfigService } from "app/services/remote-config.service";
import { TranslateService } from "@ngx-translate/core";
import { WaitspinerComponent } from "app/ADD/spinerload/waitspiner/waitspiner.component";
import { CTCComposeDialogComponent } from "app/ADD/compose/ctc/compose.component";
import { FormGroup } from "@angular/forms";
import { DefaultdialogComponent } from "app/ADD/messages/defaultdialog/defaultdialog.component";
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { CardCotractComponent } from "app/main/card-cotract/card-cotract.component";
import { SHAREDCONSTServiceTsService } from "app/services/sharedconstservice.ts.service";


@Component({
    selector: "app-card-list",
    templateUrl: "./card-list.component.html",
    styleUrls: ["./card-list.component.scss"],
    animations: fuseAnimations
})
export class CardListComponent implements OnInit {

    dataSource: MatTableDataSource<any>;
    displayedColumns = [
        "nameOnCard",
        "masked_pan",
        "is_prepaid",
        "activation_status", "Action"
    ];
    @ViewChild("pag") pagina: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild("filter") filter: ElementRef;

    // Private
    private _unsubscribeAll: Subject<any>;
    private renderedData: any;
    constructor(public dialog: MatDialog, public _matDialog: MatDialog, public _ProductsService: CardProductsService, public snackBar: MatSnackBar, private trnslate: TranslateService) {
        // Set the private defaults
        _ProductsService
            .getcards()
            .then(data => {
                console.log(data)
                this.dataSource = new MatTableDataSource(data["message"]);
                this.dataSource.paginator = this.pagina;
                this.dataSource.sort = this.sort;
                this.dataSource.connect().subscribe(d => this.renderedData = d);


            })
            .catch(err => { });
        this._unsubscribeAll = new Subject();
        this.dataSource = new MatTableDataSource([]);


    }

    isactive(tmp: String) {
        return (RemoteConfigService.ACTIVE_SERVICE.indexOf(tmp) > -1)
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    Sendpin(id) {
        this.opendialog()
        this._ProductsService.sendpin(id);
        this._ProductsService.onSendpin.subscribe(data => {
            if (data != null && data != undefined && data != "") {
                console.log(data)
                if (data == "OK") {
                    this.trnslate.get('STRINGS_pin_ok').subscribe(data => {
                        this.snackBar.open(data, 'X', {
                            duration: 1000, panelClass: ['blue-snackbar'],
                            horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,
                        });
                        this._ProductsService.onSendpin = new BehaviorSubject("");

                    })
                } else {
                    this.trnslate.get('STRINGS_err_002').subscribe(data => {
                        this.snackBar.open(data, 'X', {
                            duration: 1000, panelClass: ['blue-snackbar'],
                            horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,
                        });
                        this._ProductsService.oneactivatecard = new BehaviorSubject("");

                    })
                }
                this.closedialog()
            }
        })
    }
    ActivateCArd(id) {
        this.opendialog()
        this._ProductsService.Activatecard(id._id);
        this._ProductsService.oneactivatecard.subscribe(data => {
            if (data != null && data != undefined && data.data != "") {
                console.log(data)
                if (data.data == "OK") {
                    this.snackBar.open(data.message, 'X', {
                        duration: 1000,
                        panelClass: ['blue-snackbar'],
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                    });
                    this._ProductsService.oneactivatecard = new BehaviorSubject({ data: "", message: "" });
                    id.activation_status = '001';
                } else {
                    this.trnslate.get('STRINGS_err_002').subscribe(datatmp => {
                        this.snackBar.open(datatmp, 'X', {
                            duration: 1000,
                            panelClass: ['blue-snackbar'],
                            horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,
                        });
                        this._ProductsService.oneactivatecard = new BehaviorSubject({ data: "", message: "" });

                    })

                }
                this.closedialog()
            }

        })
    }
    DeActivateCArd(id) {
        this.opendialog()

        this._ProductsService.deActivatecard(id._id);
        this._ProductsService.ondeactivatecard.subscribe(data => {
            if (data != null && data != undefined && data.data != "") {
                console.log(data)
                if (data.data == "OK") {
                    this.snackBar.open(data.message, 'X', {
                        duration: 1000,
                        panelClass: ['blue-snackbar'],
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                    });
                    this._ProductsService.ondeactivatecard = new BehaviorSubject({ data: "", message: "" });
                    id.activation_status = '002';
                } else {
                    this.trnslate.get('STRINGS_err_002').subscribe(datatmp => {
                        this.snackBar.open(datatmp, 'X', {
                            duration: 1000,
                            panelClass: ['blue-snackbar'],
                            horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,
                        });
                        this._ProductsService.ondeactivatecard = new BehaviorSubject({ data: "", message: "" });

                    })

                }
                this.closedialog()

            }
        })
    }
    getbalance(id) {
        this.opendialog()

        this._ProductsService.getbalance(id);
        this._ProductsService.onegetbalancecard.subscribe(data => {
            if (data != null && data != undefined && data.data != "") {
                console.log(data)
                if (data.data == "OK") {
                    // this.snackBar.open(data.message, 'X', {
                    //     duration: 1000,
                    //     horizontalPosition: this.horizontalPosition,
                    //     verticalPosition: this.verticalPosition,
                    // });
                    this.closedialog()

                    this.dialogRef = this.dialog.open(DefaultdialogComponent, {
                        data: {
                            nameString: 2,
                            dataString: data.message
                        }
                    });
                    this._ProductsService.onegetbalancecard = new BehaviorSubject({ data: "", message: "" });
                } else {
                    this.trnslate.get('STRINGS_err_002').subscribe(datatmp => {
                        if (datatmp.data == "002")
                            this.snackBar.open(datatmp, 'X', {
                                duration: 1000,
                                panelClass: ['blue-snackbar'],
                                horizontalPosition: this.horizontalPosition,
                                verticalPosition: this.verticalPosition,
                            });
                        else {
                            this.snackBar.open(data.message, 'X', {
                                duration: 1000,
                                panelClass: ['blue-snackbar'],
                                horizontalPosition: this.horizontalPosition,
                                verticalPosition: this.verticalPosition,
                            });
                        }
                        this._ProductsService.onegetbalancecard = new BehaviorSubject({ data: "", message: "" });
                    })

                }

            }
        })
    }
    getlimite(id) {
        this.opendialog()
        console.log("getlimit")
        this._ProductsService.getlimite(id);
        this._ProductsService.onegetlimitcard.subscribe(data => {
            if (data != null && data != undefined && data.data != "") {
                console.log(data)
                if (data.data == "OK") {
                    // this.snackBar.open(data.message, 'X', {
                    //     duration: 1000,
                    //     horizontalPosition: this.horizontalPosition,
                    //     verticalPosition: this.verticalPosition,
                    // });
                    this.closedialog()

                    this.dialogRef = this.dialog.open(DefaultdialogComponent, {
                        data: {
                            nameString: 1,
                            dataString: data.message
                        }
                    });

                    this._ProductsService.onegetlimitcard = new BehaviorSubject({ data: "", message: "" });
                } else {
                    this.trnslate.get('STRINGS_err_002').subscribe(datatmp => {
                        if (datatmp.data == "002")
                            this.snackBar.open(datatmp, 'X', {
                                duration: 1000,

                                panelClass: ['blue-snackbar'],
                                horizontalPosition: this.horizontalPosition,
                                verticalPosition: this.verticalPosition,
                            });
                        else {
                            this.snackBar.open(data.message, 'X', {
                                duration: 1000,
                                panelClass: ['blue-snackbar'],
                                horizontalPosition: this.horizontalPosition,
                                verticalPosition: this.verticalPosition,
                            });
                        }
                        this.closedialog()
                        this._ProductsService.onegetlimitcard = new BehaviorSubject({ data: "", message: "" });
                    })

                }

            }
        })
    }

    ngOnInit() {
        this.innerWidth = window.innerWidth;
        if (window.innerWidth < 800) {
            console.log("OK")
            this.okresize = true
        } else {
            console.log("OK")
            this.okresize = false
        }
    }
    dialogRef: any;
    opendialog() {
        this.dialogRef = this.dialog.open(WaitspinerComponent, {
        });

        this.dialogRef.afterClosed().subscribe(result => {

        });
        this.dialogRef.disableClose = true;
    }

    closedialog() {
        if (this.dialogRef != undefined && this.dialogRef != null)
            this.dialogRef.close()
    }

    dialogRefctc: any;

    composeDialog(dataF): void {
        console.log(dataF.masked_pan)
        this.dialogRefctc = this.dialog.open(CTCComposeDialogComponent, {
            data: {
                nameString: 2,
                mask: dataF.masked_pan
            }
            ,
            panelClass: 'mail-compose-dialog'
        });
        this.dialogRefctc.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const formData: FormGroup = response[1];
                switch (actionType) {
                    /**
                     * Send
                     */
                    case 'send':
                        console.log('new Mail', formData.getRawValue());


                        let data = {

                            amount: formData.getRawValue().amount,
                            cardToReceivePan: formData.getRawValue().to,
                            cvv2: formData.getRawValue().CVV,
                            currency: SHAREDCONSTServiceTsService.currency_symbols_code.SAR,
                            expiryDate: formData.getRawValue().YY + "" + formData.getRawValue().MM

                        }
                        console.log(data)
                        this.opendialog()
                        this._ProductsService.TranCTC(dataF._id, data);
                        this._ProductsService.oneCTCcard.subscribe(data => {
                            if (data != null && data != undefined && data.data != "") {
                                console.log(data)
                                if (data.data == "OK") {
                                    // this.snackBar.open(data.message, 'X', {
                                    //     duration: 1000,
                                    //     horizontalPosition: this.horizontalPosition,
                                    //     verticalPosition: this.verticalPosition,
                                    // });
                                    this.closedialog()

                                    this.dialogRef = this.dialog.open(DefaultdialogComponent, {
                                        data: {
                                            nameString: 1,
                                            dataString: data.message
                                        }
                                    });

                                    this._ProductsService.oneCTCcard = new BehaviorSubject({ data: "", message: "" });
                                } else {
                                    this.trnslate.get('STRINGS_err_002').subscribe(datatmp => {
                                        // tslint:disable-next-line: triple-equals
                                        if (datatmp.data == '002') {
                                            this.snackBar.open(datatmp, 'X', {
                                                duration: 1000,

                                                panelClass: ['blue-snackbar'],
                                                horizontalPosition: this.horizontalPosition,
                                                verticalPosition: this.verticalPosition,
                                            });
                                        }
                                        else {
                                            this.snackBar.open(data.message, 'X', {
                                                duration: 1000,
                                                panelClass: ['blue-snackbar'],
                                                horizontalPosition: this.horizontalPosition,
                                                verticalPosition: this.verticalPosition,
                                            });
                                        }
                                        this.closedialog()
                                        this._ProductsService.oneCTCcard = new BehaviorSubject({ data: "", message: "" });
                                    })

                                }

                            }
                        })



                        break;
                    /**
                     * Delete
                     */
                    case 'delete':
                        console.log('delete Mail');
                        break;
                }
            });
    }

    dialogRefctc2: any
    composeDialogupdateContract(): void {
        this.dialogRefctc2 = this._matDialog.open(CardCotractComponent, {
            panelClass: 'mail-compose-dialog'
        });
        this.dialogRefctc2.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const formData: FormGroup = response[1];
                switch (actionType) {
                    /**
                     * Send
                     */
                    case 'send':
                        console.log('new', formData.getRawValue());
                        break;
                    /**
                     * Delete
                     */
                    case 'delete':
                        console.log('delete');
                        break;
                }
            });
    }

    CSV() {


        let data = []
        var options = {

            showLabels: true,
            showTitle: true,
            title: '',
            useBom: true,
            noDownload: false,
            headers: 
                ["nameOnCard",
                "masked_pan",
                "is_prepaid",
                "activation_status",
                "Action"]
        };

        
        //JSON.parse(JSON.stringify()


        this.renderedData.forEach(element => {
            let tmpdata = {};
            tmpdata['nameOnCard'] = element["nameOnCard"];
            tmpdata['masked_pan'] = element["masked_pan"];
            tmpdata['is_prepaid'] = element['is_prepaid'];
            tmpdata['activation_status'] = element["activation_status"];
            data.push(tmpdata)
        });

        console.log(this.renderedData);

        new ngxCsv(data, 'Report' + new Date().toDateString());

    }

    innerWidth: any
    okresize = false
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
        if (window.innerWidth < 800) {
            console.log("OK")
            this.okresize = true
        } else {
            console.log("OK")
            this.okresize = false
        }
    }



}

