import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot
} from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { SHAREDCONSTServiceTsService } from "app/services/sharedconstservice.ts.service";
import { AngularFireAuth } from "@angular/fire/auth";

@Injectable()
export class tagsProductsService {
    products: any[];
    onProductsChanged: BehaviorSubject<any>;
    constructor(private _httpClient: HttpClient, private SHAREDCONSTServiceTsServiceimp: SHAREDCONSTServiceTsService, private afAuth: AngularFireAuth
    ) {
        // Set the defaults
        this.onProductsChanged = new BehaviorSubject({});
    }
    tocken = ""
    getcards(): Promise<any> {
        var headerstmp = {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + SHAREDCONSTServiceTsService.UserTocken
        };
        console.log(headerstmp);
        return new Promise((resolve, reject) => {
            this._httpClient
                .post<any>(
                    SHAREDCONSTServiceTsService.BASE_URL + "tags/tag-list",
                    { tagCode: "" },
                    {
                        headers: headerstmp
                    }
                )
                .subscribe((response: any) => {
                    console.log(JSON.stringify(response));
                    this.products = response;
                    this.onProductsChanged.next(this.products);
                    resolve(response);
                }, reject);
        });
    }
    getstatuscard(S: String) {
        if (S == "002") return "STRINGS_deactiv";
        if (S == "001") return "STRINGS_activ";//"activ"));
        if (S == "003") return "STRINGS_deactiv";//MyApp.StringsRepository.get("deactiv"));
        if (S == "004") return "STRINGS_canceled";//MyApp.StringsRepository.get("canceled") == null ? "Canceled" : MyApp.StringsRepository.get("canceled"));
    }
}
