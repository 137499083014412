import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

export interface DialogData {
    mask: number;
}
@Component({
    selector: 'CTC-compose',
    templateUrl: './compose.component.html',
    styleUrls: ['./compose.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CTCComposeDialogComponent {
    showExtraToFields: boolean;
    composeForm: FormGroup;

    /**
     * Constructor
     *
     * @param {MatDialogRef<MailComposeDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        public matDialogRef: MatDialogRef<CTCComposeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: DialogData
    ) {
        // Set the defaults
        console.log(_data)
        this.composeForm = this.createComposeForm(_data.mask);
        this.showExtraToFields = false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create compose form
     *
     * @returns {FormGroup}
     */
    createComposeForm(mask): FormGroup {
        return new FormGroup({
            from: new FormControl({
                value: mask,
                disabled: true
            }),
            to: new FormControl('', [Validators.minLength(16), Validators.maxLength(16)]),
            MM: new FormControl('', [Validators.minLength(2), Validators.maxLength(2)]),
            YY: new FormControl('', [Validators.minLength(2), Validators.maxLength(2)]),
            CVV: new FormControl('', [Validators.minLength(3), Validators.maxLength(3)]),
            amount: new FormControl('', [Validators.minLength(2), Validators.maxLength(7)])
        });
    }

    /**
     * Toggle extra to fields
     */
    toggleExtraToFields(): void {
        this.showExtraToFields = !this.showExtraToFields;
    }
}
