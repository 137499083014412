import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthTsService } from 'app/services/auth.service.ts.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SHAREDCONSTServiceTsService } from 'app/services/sharedconstservice.ts.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations : fuseAnimations
})
export class LoginComponent implements OnInit
{
    loginForm: FormGroup;
    AuthTsServiceImp:AuthTsService;
    languages = [
        {
            id   : 'en',
            title: 'English',
            flag : 'us'
        },
        {
            id   : 'ar',
            title: 'Arabic',
            flag : 'ar'
        },
        {
            id   : 'fr',
            title: 'Françai',
            flag : 'fr'
        }
    ];
    selectedLanguage: any= "EN";

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder
        ,        private router: Router,

        private _translateService: TranslateService,
        private AuthTsService: AuthTsService
    )
    {
        this.AuthTsServiceImp = this.AuthTsService
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        this.selectedLanguage = _.find(this.languages, {'id': this._translateService.currentLang});

    }
    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void
    {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    gorest(){
        
        SHAREDCONSTServiceTsService.pageschange.next("restpass");
    }

    GOedit(){
        SHAREDCONSTServiceTsService.pageschange.next("register");
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.loginForm = this._formBuilder.group({
            email   : ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
    }

    SignIn(ust , pass ) {
        this.AuthTsServiceImp.SignIn(ust , pass);
    }
    
}
