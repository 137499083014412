import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-card-cotract',
  templateUrl: './card-cotract.component.html',
  styleUrls: ['./card-cotract.component.scss']
})
export class CardCotractComponent implements OnInit {
    ngOnInit(): void {
    }



    showExtraToFields: boolean;
    composeForm: FormGroup;

    /**
     * Constructor
     *
     * @param {MatDialogRef<CardCotractComponent>} matDialogRef
     * @param _data
     */
    constructor(
        public matDialogRef: MatDialogRef<CardCotractComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
        // Set the defaults
        this.composeForm = this.createComposeForm()
        this.showExtraToFields = false;
    }

    createComposeForm(): FormGroup {
        return new FormGroup({
           
        });
    }

    /**
     * Toggle extra to fields
     */
    toggleExtraToFields(): void {
        this.showExtraToFields = !this.showExtraToFields;
    }



}
