import { Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import {
  MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatDialog,
} from '@angular/material';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';

import { takeUntil } from 'rxjs/internal/operators';
import { RemoteConfigService } from 'app/services/remote-config.service';
import { TranslateService } from '@ngx-translate/core';
import { WaitspinerComponent } from 'app/ADD/spinerload/waitspiner/waitspiner.component';
import { CTCComposeDialogComponent } from 'app/ADD/compose/ctc/compose.component';
import { FormGroup } from '@angular/forms';
import { DefaultdialogComponent } from 'app/ADD/messages/defaultdialog/defaultdialog.component';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { CardCotractComponent } from 'app/main/card-cotract/card-cotract.component';
import { SHAREDCONSTServiceTsService } from 'app/services/sharedconstservice.ts.service';
import { CardProductsService } from '../../card-list/products.service';
import {AngularFireStorage} from "@angular/fire/storage";

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  animations: fuseAnimations
})
export class InvoicesComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  displayedColumns = [
    'nameOnCard',
    'masked_pan',
    'is_prepaid',
    'activation_status'
  ];
  @ViewChild('pag') pagina: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filter') filter: ElementRef;
  data = []
  // Private
  private _unsubscribeAll: Subject<any>;
  private renderedData: any;
  // tslint:disable-next-line: max-line-length
  constructor(public dialog: MatDialog, public _matDialog: MatDialog, public _ProductsService: CardProductsService, private storage  : AngularFireStorage , 
    public snackBar: MatSnackBar, private trnslate: TranslateService) {
    // Set the private defaults
    _ProductsService
      .getInvo(this.data)

    _ProductsService
      .oneInvo.asObservable().subscribe
      (data => {
        if (data != null && data != undefined) {
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.pagina;
          this.dataSource.sort = this.sort;
          this.dataSource.connect().subscribe(d => this.renderedData = d);
        }
      })
    this.dataSource = new MatTableDataSource([]);

    this.dataSource.paginator = this.pagina;
    this.dataSource.sort = this.sort;
    this.dataSource.connect().subscribe(d => this.renderedData = d);
    this._unsubscribeAll = new Subject();
  }


  isactive(tmp: String) {
    return (RemoteConfigService.ACTIVE_SERVICE.indexOf(tmp) > -1)
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  // tslint:disable-next-line: member-ordering

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';

  // tslint:disable-next-line: member-ordering

  verticalPosition: MatSnackBarVerticalPosition = 'top';

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (window.innerWidth < 800) {
      console.log('OK')
      this.okresize = true
    } else {
      console.log('OK')
      this.okresize = false
    }
  }

  dialogRef: any;

  opendialog() {
    this.dialogRef = this.dialog.open(WaitspinerComponent, {
    });

    this.dialogRef.afterClosed().subscribe(result => {

    });
    this.dialogRef.disableClose = true;
  }

  closedialog() {
    if (this.dialogRef != undefined && this.dialogRef != null)
      this.dialogRef.close()
  }

  dialogRefctc: any;

  composeDialog(dataF): void {
    console.log(dataF.masked_pan)
    this.dialogRefctc = this.dialog.open(CTCComposeDialogComponent, {
      data: {
        nameString: 2,
        mask: dataF.masked_pan
      }
      ,
      panelClass: 'mail-compose-dialog'
    });
    this.dialogRefctc.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
        const actionType: string = response[0];
        const formData: FormGroup = response[1];
        switch (actionType) {
          /**
           * Send
           */
          case 'send':
            console.log('new Mail', formData.getRawValue());


            let data = {

              amount: formData.getRawValue().amount,
              cardToReceivePan: formData.getRawValue().to,
              cvv2: formData.getRawValue().CVV,
              currency: SHAREDCONSTServiceTsService.currency_symbols_code.SAR,
              expiryDate: formData.getRawValue().YY + '' + formData.getRawValue().MM

            }
            console.log(data)
            this.opendialog()
            this._ProductsService.TranCTC(dataF._id, data);
            this._ProductsService.oneCTCcard.subscribe(data => {
              if (data != null && data != undefined && data.data != '') {
                console.log(data)
                if (data.data == 'OK') {
                  // this.snackBar.open(data.message, 'X', {
                  //     duration: 1000,
                  //     horizontalPosition: this.horizontalPosition,
                  //     verticalPosition: this.verticalPosition,
                  // });
                  this.closedialog()

                  this.dialogRef = this.dialog.open(DefaultdialogComponent, {
                    data: {
                      nameString: 1,
                      dataString: data.message
                    }
                  });

                  this._ProductsService.oneCTCcard = new BehaviorSubject({ data: '', message: '' });
                } else {
                  this.trnslate.get('STRINGS_err_002').subscribe(datatmp => {
                    // tslint:disable-next-line: triple-equals
                    if (datatmp.data == '002') {
                      this.snackBar.open(datatmp, 'X', {
                        duration: 1000,

                        panelClass: ['blue-snackbar'],
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                      });
                    }
                    else {
                      this.snackBar.open(data.message, 'X', {
                        duration: 1000,
                        panelClass: ['blue-snackbar'],
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                      });
                    }
                    this.closedialog()
                    this._ProductsService.oneCTCcard = new BehaviorSubject({ data: '', message: '' });
                  })

                }

              }
            })



            break;
          /**
           * Delete
           */
          case 'delete':
            console.log('delete Mail');
            break;
        }
      });
  }

  dialogRefctc2: any
  composeDialogupdateContract(): void {
    this.dialogRefctc2 = this._matDialog.open(CardCotractComponent, {
      panelClass: 'mail-compose-dialog'
    });
    this.dialogRefctc2.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
        const actionType: string = response[0];
        const formData: FormGroup = response[1];
        switch (actionType) {
          /**
           * Send
           */
          case 'send':
            console.log('new', formData.getRawValue());
            break;
          /**
           * Delete
           */
          case 'delete':
            console.log('delete');
            break;
        }
      });
  }

  CSV() {


    let data = []
    var options = {

      showLabels: true,
      showTitle: true,
      title: '',
      useBom: true,
      noDownload: false,
      headers:
        ['nameOnCard',
          'masked_pan',
          'is_prepaid',
          'activation_status',
          'Action']
    };


    //JSON.parse(JSON.stringify()


    this.renderedData.forEach(element => {
      let tmpdata = {};
      tmpdata['nameOnCard'] = element['nameOnCard'];
      tmpdata['masked_pan'] = element['masked_pan'];
      tmpdata['is_prepaid'] = element['is_prepaid'];
      tmpdata['activation_status'] = element['activation_status'];
      data.push(tmpdata)
    });

    console.log(this.renderedData);

    new ngxCsv(data, 'Report' + new Date().toDateString());

  }

  innerWidth: any
  okresize = false
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (window.innerWidth < 800) {
      console.log('OK')
      this.okresize = true
    } else {
      console.log('OK')
      this.okresize = false
    }
  }


  OpenFile(href){
   this.storage.ref(href).getDownloadURL().subscribe( (url) =>{
        window.open(url, "_blank");
    })
  }
}

