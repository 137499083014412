import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { SHAREDCONSTServiceTsService } from "app/services/sharedconstservice.ts.service";
import { AngularFireAuth } from "@angular/fire/auth";

@Injectable()
export class mailService {

    onSendpin: BehaviorSubject<any>;


    constructor(private _httpClient: HttpClient, private SHAREDCONSTServiceTsServiceimp: SHAREDCONSTServiceTsService, private afAuth: AngularFireAuth
    ) {
        // Set the defaults

        this.onSendpin = new BehaviorSubject("");

    }


    tocken = ""

    SendMail(user, emailObject, emailDescription) {
        var headerstmp = {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + SHAREDCONSTServiceTsService.UserTocken
        };
        this.onSendpin = new BehaviorSubject("");
        console.log(headerstmp);
        return new Promise((resolve, reject) => {
            this._httpClient
                .post<any>(
                    SHAREDCONSTServiceTsService.BASE_URL + 'utils/sendMail',
                    {
                        emailData: {
                            senderName: user.displayName,
                            senderEmail: user.email,
                            senderMessageSubject: emailObject,
                            senderMessage: emailDescription
                        }
                    },
                    {
                        headers: headerstmp
                    }
                )
                .subscribe((response: any) => {
                    this.onSendpin.next("OK");
                }, reject => {
                    console.log(reject)
                    this.onSendpin.next(reject.error.message);
                });
        });
    }






}
