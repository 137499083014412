import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {
    MatButtonModule, MatIconModule, MatInputModule, MatCheckboxModule,
    MatChipsModule, MatTableModule, MatTabsModule, MatSnackBarModule,
    MatPaginatorModule, MatFormFieldModule, MatProgressSpinnerModule, MatDialogModule, MatOptionModule, MatSelectModule, MatToolbarModule, MatTooltipModule, MatMenuModule, MatGridListModule, MatButtonToggleModule, MatDatepicker, MatDatepickerModule
} from '@angular/material';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { LoginComponent } from './main/login/login.component';
import { RegisterComponent } from './main/register/register.component';
import { ForgetPasswordComponent } from './main/forget-password/forget-password.component';
import { CardListComponent } from './main/card/card-list/card-list.component';
import { CardProductsService } from './main/card/card-list/products.service';

import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import {AngularFireStorageModule} from "@angular/fire/storage";

import { ListTagsComponent } from './main/tags/list-tags/list-tags.component';
import { ListstationsComponent } from './main/maps/liststations/liststations.component';
import { AgmCoreModule } from '@agm/core';
import { tagsProductsService } from './main/tags/list-tags/products.service';
import { ListTransactionComponent } from './main/card/list-transaction/list-transaction.component';
import { TransactionProductsService } from './main/card/list-transaction/products.service';
import { WaitspinerComponent } from './ADD/spinerload/waitspiner/waitspiner.component';
import { ProfileComponent } from './main/user/profile/profile.component';
import { DefaultdialogComponent } from './ADD/messages/defaultdialog/defaultdialog.component';
import { MailComposeDialogComponent } from './ADD/compose/compose.component';
import { CTCComposeDialogComponent } from './ADD/compose/ctc/compose.component';
import { mailService } from './ADD/compose/products.service';
import { ActivateuserComponent } from './main/activateuser/activateuser.component';
import { StationdetailComponent } from './ADD/stationdetail/stationdetail.component';
import { CardCotractComponent } from './main/card-cotract/card-cotract.component';
import { DateComponent } from './ADD/date/date.component';
import { StatmentsComponent } from './main/card/fct/statments/statments.component';
import { InvoicesComponent } from './main/card/fct/invoices/invoices.component';

const appRoutes: Routes = [
    {
        path: '**',
        redirectTo: 'cards'
    }
];

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        RegisterComponent,
        ForgetPasswordComponent,
        CardListComponent,
        ListTagsComponent,
        ListstationsComponent,
        ListTransactionComponent,
        WaitspinerComponent, MailComposeDialogComponent, CTCComposeDialogComponent,
        ProfileComponent,
        DefaultdialogComponent,
        ActivateuserComponent,
        StationdetailComponent,
        CardCotractComponent,
        DateComponent,
        InvoicesComponent,
        StatmentsComponent
    ],
    imports: [
        BrowserModule, AgmCoreModule.forRoot({ apiKey: "AIzaSyBNri9-rFDEP7dvYTEs9iaKwzQA8XGMUj8" }),
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        MatTooltipModule,
        MatMenuModule,
        TranslateModule.forRoot(),

        MatPaginatorModule, MatOptionModule, MatSelectModule
        , MatToolbarModule,

        // Material moment date module
        MatMomentDateModule, MatSnackBarModule,
        MatInputModule, MatCheckboxModule,MatGridListModule,
        MatButtonModule, MatProgressSpinnerModule,
        MatFormFieldModule,

        // Material
        MatTabsModule,
        MatDialogModule,
        MatIconModule, MatDatepickerModule,
        MatChipsModule,
        MatTableModule,MatButtonToggleModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        //firebase  Config  
        AngularFireModule.initializeApp({
            apiKey: "AIzaSyA1C9jLfpNHHYK2U0HUnW24nOw99rIUlLY",
            authDomain: "cr-sasco-prod.firebaseapp.com",
            databaseURL: "https://cr-sasco-prod.firebaseio.com",
            projectId: "cr-sasco-prod",
            storageBucket: "cr-sasco-prod.appspot.com",
            messagingSenderId: "279960438909",
            appId: "1:279960438909:web:7538cdf12a9153e9",
            measurementId: "G-7VFC0DJJ8N"
        }),
        AngularFireAuthModule,
        AngularFireStorageModule,
        AngularFirestoreModule,
        // App modules
        LayoutModule,
        SampleModule
    ],
    bootstrap: [
        AppComponent
    ], providers: [
        CardProductsService, tagsProductsService, TransactionProductsService, mailService
    ], entryComponents: [
        WaitspinerComponent, MailComposeDialogComponent, CTCComposeDialogComponent, DefaultdialogComponent, StationdetailComponent, CardCotractComponent , DateComponent

    ], exports: [TranslateModule]
})
export class AppModule {
}
