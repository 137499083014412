import { Injectable } from "@angular/core";
import { FuseNavigation, FuseNavigationItem } from '@fuse/types';

import { HttpClient } from "@angular/common/http";
import { SHAREDCONSTServiceTsService } from "./sharedconstservice.ts.service";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import { BehaviorSubject } from "rxjs";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore";

@Injectable({
    providedIn: "root"
})
export class RemoteConfigService {
    private messageSource = new BehaviorSubject('REMOTECONFIG');
    currentMessage = this.messageSource.asObservable();
    constructor(private _httpClient: HttpClient, private _fuseTranslationLoaderService: FuseTranslationLoaderService, private _AngularFirestore: AngularFirestore
    ) {
        this.SetMenu()
    }
    static ACTIVE_SERVICE: String[] = []
    init(): void {
        //GeT RemoteConfig
        this._httpClient
            .get(SHAREDCONSTServiceTsService.BASE_URL + "utils/remoteConfig")
            .subscribe((response: any) => {
                let lk = response["message"];
                let headerstmp = {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + lk
                };
                console.log("1")
                //get KEY FROM FUNCTOIN 
                this._httpClient
                    .get(
                        "https://firebaseremoteconfig.googleapis.com/v1/projects/cr-sasco-prod/remoteConfig",
                        {
                            headers: headerstmp
                        }
                    )
                    .subscribe((data) => {
                        console.log("2")

                        let ALLDATA = JSON.parse(JSON.stringify(data))[
                            "parameters"
                        ];
                        const localeFR = {
                            lang: "fr",
                            data: {
                                STRINGS: {
                                    HELLO: "Hello, World!"
                                }
                            }
                        };
                        var localeAR = {
                            lang: "ar",
                            data: {
                                STRINGS: {
                                    HELLO: "Hello, World!"
                                }
                            }
                        };
                        const localePT = {
                            lang: "pt",
                            data: {
                                STRINGS: {
                                    HELLO: "Hello, World!"
                                }
                            }
                        };
                        const localeEN = {
                            lang: "en",
                            data: {
                                STRINGS: {
                                    HELLO: "Hello, World!"
                                }
                            }
                        };
                        //FILTER CONFIG
                        JSON.parse(JSON.stringify(ALLDATA));
                        Object.keys(ALLDATA).forEach(element => {

                            localeAR["data"][element] = ALLDATA[element]["conditionalValues"]["str_ar"] == null
                                ? ALLDATA[element]["defaultValue"]["value"]
                                : ALLDATA[element]["conditionalValues"]["str_ar"]["value"]
                            localeFR["data"][element] = ALLDATA[element]["conditionalValues"]["str_fr"]== null
                                ? ALLDATA[element]["defaultValue"]["value"]
                                : ALLDATA[element]["conditionalValues"]["str_fr"]["value"]
                            localePT["data"][element] = ALLDATA[element]["conditionalValues"]["str_pt"] == null
                                ? ALLDATA[element]["defaultValue"]["value"]
                                : ALLDATA[element]["conditionalValues"]["str_pt"]["value"]
                            localeEN["data"][element] = ALLDATA[element]["conditionalValues"]["str_en"] == null
                                ? ALLDATA[element]["defaultValue"]["value"]
                                : ALLDATA[element]["conditionalValues"]["str_en"]["value"]

                        });

                        this._fuseTranslationLoaderService.loadTranslations(localeAR, localeFR, localeEN, localePT)
                        this.messageSource.next("OK_CONFIG")

                    }, reject => {
                        console.log(reject)
                    });
            }, reject => {
                console.log(reject)
            });
    }
    static navigation: FuseNavigation[] = []
    isone = true
    //GET ACTIVATE SERVICE 
    SetMenu() {
        this._AngularFirestore.collection('cr_modules', ref => ref.where('parent_id', '==', null)
            .where("is_enabled", "==", true)).valueChanges().subscribe(res => {
                RemoteConfigService.navigation.forEach(itm => {
                    const index: number =  RemoteConfigService.navigation.indexOf(itm);
                        if (index !== -1) {
                            RemoteConfigService.navigation.splice(index, 1);
                        }
                        RemoteConfigService.navigation.length = 0 
                })
                res.sort((a, b)=>{return b["id"] - a["id"]})
                res.forEach(data => { 
                    RemoteConfigService.navigation.push({
                        id: data["id"],
                        title: data['remote_config_id'],
                        translate: data['remote_config_id'],
                        type: data['type'],
                        children: []
                    })
                })
                console.log("1-2")
                this.getChils()
            });
        this._AngularFirestore.collection('cr_modules', ref => ref.where('type', '==', 'action')
            .where('is_enabled', '==', true)).valueChanges().subscribe(res2 => {
                res2.forEach(tmp => {
                    RemoteConfigService.ACTIVE_SERVICE.push(tmp['id'])
                })
                //this.init()
            });
    }

    getChils() {
        RemoteConfigService.navigation.forEach(S => {
            console.log(S.id);
            
            this._AngularFirestore.collection('cr_modules', ref => ref.where('parent_id', '==', S.id)
                .where("is_enabled", "==", true)).valueChanges().subscribe(res2 => {
                    S.children = []
                    console.log("2-1")

                    res2.forEach(tmp => {
                        console.log("chiled => " + tmp['id'])
                        var chileditem: FuseNavigationItem;
                        chileditem = {
                            id: tmp['id'],
                            title: tmp['remote_config_id'],
                            translate: tmp['remote_config_id'],
                            type: tmp['type'],
                            icon: tmp['icon_path'],
                            url: tmp["href"],
                        }
                        S.children.push(
                            chileditem
                        )

                    })
                    console.log('--------------------->');
                    console.log(RemoteConfigService.navigation)
                    console.log("2-3")

                    this.init();
                });
        })

    }







}
